<template>
  <LayoutContainer class="relative flex h-full select-none flex-col bg-white">
    <div class="relative grow">
      <div class="absolute inset-0">
        <NuxtPage />
      </div>
    </div>

    <template v-if="dialogOptions">
      <DialogConfirm
        v-bind="dialogOptions"
        @confirm="closeDialog(true)"
        @cancel="closeDialog(false)"
        @close="dialogOptions.force ? null : closeDialog(undefined)"
      />
    </template>

    <!-- <ZipupNavbar class="absolute bottom-0"></ZipupNavbar> -->

    <ZipupNavbar class="w-full z-30 flex flex-none"></ZipupNavbar>

    <template v-if="loading">
      <DialogLoading />
    </template>
  </LayoutContainer>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useDialogStore } from '~/store/dialog'
import { useImageViewerStore } from '~/store/imageViewer'
import { useLoadingStore } from '~/store/loading'

export default defineComponent({
  setup() {
    const dialogStore = useDialogStore()
    const imageViewerStore = useImageViewerStore()
    const loadingStore = useLoadingStore()

    const dialogOptions = computed(() => dialogStore.options)
    const imageViewerOptions = computed(() => imageViewerStore.options)
    const loading = computed(() => loadingStore.opened)

    const closeDialog = (confirmed?: boolean) => {
      dialogStore.close(confirmed)
    }

    const closeImageViewer = () => {
      imageViewerStore.close()
    }

    const closeLoading = () => {
      loadingStore.close()
    }

    return {
      loading,
      dialogOptions,
      imageViewerOptions,
      closeDialog,
      closeImageViewer,
      closeLoading,
    }
  },
})
</script>
